import React, { useContext } from "react"
import CustomLink from "./CustomLink"
import styles from '@/styles/CMS/Header.module.scss'
import AuthContext from "@/app/contexts/AuthContext"
import { useRouter } from "next/router"

const HeaderCMS: React.FC<any> = ({ setNewBanner }) => {
    const authContext = useContext(AuthContext)
    const router = useRouter()

    if (!authContext) {
        throw new Error('AuthContext must be used within an AuthProvider')
    }

    const { logout } = authContext

    const getLinkClass = (path: string) => {
        return router.pathname === path ? `${styles.activeLink}` : ''
    }

    return (
        <header>
            <div className={styles.menu}>
                <CustomLink onClick={() => setNewBanner(false)} href='/cms/dashboard'>
                    <img src="/img/byd/cms/logo.svg" alt="CMS BYD" width={194} height={56} />
                </CustomLink>
                <nav>
                    <ul>
                        <li>
                            <CustomLink 
                                onClick={() => setNewBanner(false)} 
                                href="/cms/dashboard"
                                className={getLinkClass('/cms/dashboard')}
                            >
                                Home
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink 
                                onClick={() => setNewBanner(false)} 
                                href="/cms/dashboard/consorcio"
                                className={getLinkClass('/cms/dashboard/consorcio')}
                            >
                                Consórcio
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink 
                                onClick={() => setNewBanner(false)} 
                                href="/cms/dashboard/pos-venda"
                                className={getLinkClass('/cms/dashboard/pos-venda')}
                            >
                                Pós-venda
                            </CustomLink>
                        </li>
                        {/*<li>
                            <CustomLink 
                                onClick={() => setNewBanner(false)} 
                                href="/cms/dashboard/metadata"
                                className={getLinkClass('/cms/dashboard/metadata')}
                            >
                                Metadados
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink 
                                onClick={() => setNewBanner(false)} 
                                href="/cms/dashboard/location"
                                className={getLinkClass('/cms/dashboard/location')}
                            >
                                Localização
                            </CustomLink>
                        </li>*/}
                    </ul>
                </nav>
                <button type="button" onClick={logout}>
                    <img src="/img/byd/cms/logout.svg" alt="" width={23} height={21} />Sair
                </button>
            </div>
        </header>
    )
}

export default React.memo(HeaderCMS)
