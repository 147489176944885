const handleSubmitChatbot = async (
    body: any,
    landingPage: string,
    selectedCar: string,
    params: string,
    team: string
) => {
    try {
        // Fazer a requisição para a API interna
        const response = await fetch('/api/submitChatbot', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...body,
                landingPage,
                selectedCar,
                params,
                team
            }),
        })

        if (!response.ok) {
            throw new Error('Erro ao enviar os dados para a API interna.')
        }

        // API retornou com sucesso, agora disparar o evento de conversão
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
            window.gtag('event', 'conversionChatbot', {
                'send_to': 'AW-10941524448/5hNOCPzqsuEYEODTqeEo'
            })
        }
    } catch (error) {
        if (error instanceof Error) {
            console.error('Erro:', error.message)
        } else {
            console.error('Um erro desconhecido ocorreu:', error)
        }
    }
}

export default handleSubmitChatbot
