import { useCallback } from "react"
import { FormStateProps } from "../interface/components"
import { Option } from "../interface/components"

export const optionsChatbot = [
    {
        team: 'ES',
        unit: [
            'BYD Vila Velha',
            'BYD Vitória',
            'BYD Serra',
            'BYD Linhares',
            'BYD Cachoeiro de Itapemirim'
        ],
        id_unit: [
            13012,
            13013,
            16651,
            13014,
            13015
        ]
    },
    {
        team: 'GO',
        unit: [
            'BYD Goiânia',
            'BYD Goiânia - Flamboyant Shopping',
            'BYD Rio Verde',
            'BYD Aparecida de Goiânia',
            'BYD Anápolis'
        ],
        id_unit: [
            13023,
            14994,
            13024,
            14992,
            14993
        ]
    },
    {
        team: 'BSB',
        unit: [
            'BYD Asa Norte - Brasília',
            'BYD - SIA'
        ],
        id_unit: [
            14895,
            17599
        ]
    },
    {
        team: 'MG',
        unit: [
            'BYD Belo Horizonte - Barão',
            'BYD Belo Horizonte - Raja',
            'BYD Juiz de Fora',
            'BYD Uberlândia',
            'BYD Ipatinga',
            'BYD Governador Valadares',
            'BYD Montes Claros',
            'BYD Uberaba',
            'BYD Varginha',
            'BYD Pouso Alegre',
            'BYD Divinópolis',
            'BYD Poços de Caldas',
            'BYD Pampulha'
        ],
        id_unit: [
            13016,
            16545,
            13020,
            13017,
            13021,
            22351,
            13022,
            13019,
            13018,    
            13745,
            13746,
            13744,
            18094
        ]
    }
]

export const options = [
    {
        team: 'ES',
        unit: [
            { label: 'BYD Vila Velha', region: '' },
            { label: 'BYD Vitória', region: '' },
            { label: 'BYD Serra', region: '' },
            { label: 'BYD Linhares', region: '' },
            { label: 'BYD Cachoeiro de Itapemirim', region: '' }
        ],
        id_unit: [
            13012,
            13013,
            16651,
            13014,
            13015
        ]
    },
    {
        team: 'GO',
        unit: [
            { label: 'BYD Goiânia', region: '' },
            { label: 'BYD Goiânia - Flamboyant Shopping', region: '' },
            { label: 'BYD Rio Verde', region: '' },
            { label: 'BYD Aparecida de Goiânia', region: '' },
            { label: 'BYD Anápolis', region: '' }
        ],
        id_unit: [
            13023,
            14994,
            13024,
            14992,
            14993
        ]
    },
    {
        team: 'BSB',
        unit: [
            { label: 'BYD Asa Norte - Brasília', region: '' },
            { label: 'BYD - SIA', region: '' }
        ],
        id_unit: [
            14895,
            17599
        ]
    },
    {
        team: 'MG',
        unit: [
            { label: 'BYD Belo Horizonte - Barão', region: 'bh' },
            { label: 'BYD Belo Horizonte - Raja', region: 'bh' },
            { label: 'BYD Juiz de Fora', region: 'zona-da-mata' },
            { label: 'BYD Uberlândia', region: 'tri' },
            { label: 'BYD Ipatinga', region: 'leste' },
            { label: 'BYD Governador Valadares', region: 'leste' },
            { label: 'BYD Montes Claros', region: 'norte' },
            { label: 'BYD Uberaba', region: 'tri' },
            { label: 'BYD Varginha', region: 'sul' },
            { label: 'BYD Pouso Alegre', region: 'sul' },
            { label: 'BYD Divinópolis', region: 'bh' },
            { label: 'BYD Poços de Caldas', region: 'sul' },
            { label: 'BYD Pampulha', region: 'bh' }
        ],
        id_unit: [
            13016,
            16545,
            13020,
            13017,
            13021,
            22351,
            13022,
            13019,
            13018,
            13745,
            13746,
            13744,
            18094
        ]
    }
]

export const vehicleOptions = [
    {
        label: "BYD Dolphin Mini",
        value: 87966,
        type: "eletric"
    },
    {
        label: "BYD Dolphin",
        value: 67262,
        type: "eletric"
    },
    {
        label: "BYD Dolphin Plus",
        value: 67263,
        type: "eletric"
    },
    {
        label: "BYD King",
        value: 109173,
        type: "hybrid"
    },
    {
        label: "BYD Song Pro",
        value: 109172,
        type: "hybrid"
    },
    {
        label: "BYD Song Plus",
        value: 67265,
        type: "hybrid"
    },
    {
        label: "BYD Yuan Pro",
        value: 128672,
        type: "eletric"
    },
    {
        label: "BYD Yuan Plus",
        value: 67264,
        type: "eletric"
    },
    {
        label: "BYD Seal",
        value: 67261,
        type: "eletric"
    },
    {
        label: "BYD Shark",
        value: 107015,
        type: "hybrid"
    },
    {
        label: "BYD Tan 2025",
        value: 93673,
        type: "eletric"
    },
    {
        label: "BYD Tan",
        value: 67267,
        type: "eletric"
    },
    {
        label: "BYD Han",
        value: 67266,
        type: "eletric"
    }
]

export const carOptions = [
    {
        label: 'BYD Song Pro',
        value: 'BYD SONG PRO'
    },
    {
        label: 'BYD King',
        value: 'BYD KING'
    },
    {
        label: 'BYD Shark',
        value: 'BYD SHARK'
    },
    {
        label: 'BYD Dolphin mini',
        value: 'BYD DOLPHIN MINI'
    },
    {
        label: 'BYD Dolphin',
        value: 'BYD DOLPHIN'
    },
    {
        label: 'BYD Dolphin Plus',
        value: 'BYD DOLPHIN PLUS'
    },
    {
        label: 'BYD Song Plus',
        value: 'BYD SONG PLUS'
    },
    {
        label: 'BYD Yuan Pro',
        value: 'BYD YUAN PRO'
    },
    {
        label: 'BYD Yuan Plus',
        value: 'BYD YUAN PLUS'
    },
    {
        label: 'BYD Seal',
        value: 'BYD SEAL'
    },
    {
        label: 'Novo BYD Tan',
        value: 'NOVO BYD TAN'
    },
    {
        label: 'BYD Tan',
        value: 'BYD TAN'
    },
    {
        label: 'BYD Han',
        value: 'BYD HAN'
    }
]

export const validate = (value: any, type: 'name' | 'select' | 'email' | 'phone' | 'cpf'): any => {
    if (type === 'name') {
        const splitName = value.trim().split(' ')
        const nameValid = splitName.length >= 1 && splitName.every((part: string) => part !== '')

        return nameValid
    }
    
    if (type === 'select') return value ? true : false

    if (type === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
        const emailValid = emailRegex.test(value)

        return emailValid
    }
    
    if (type === 'phone') {
        let newCell = value.replace(/\D/g, "") // Remove tudo exceto números

        if (newCell.length > 11) { // Se mais de 11 números, truncar.
            newCell = newCell.substring(0, 11)
        }

        // Formatação
        if (newCell.length === 0) {
            // não fazer nada, mantenha o valor vazio
        } else if (newCell.length <= 2) {
            newCell = newCell.replace(/^(\d{0,2})/, "($1")
        } else if (newCell.length <= 7) {
            newCell = newCell.replace(/^(\d{0,2})(\d{0,5})/, "($1) $2")
        } else {
            newCell = newCell.replace(/^(\d{0,2})(\d{0,5})(\d{0,4})/, "($1) $2-$3")
        }

        const phoneValid = newCell.length === 15 || !newCell

        return { phone: newCell, isValid: phoneValid }
    }

    if (type === 'cpf') {
        let newCpf = value.replace(/\D/g, "") // Remove tudo exceto números

        if (newCpf.length > 11) { // Se mais de 11 números, truncar.
            newCpf = newCpf.substring(0, 11)
        }

        // Formatação
        if (newCpf.length === 0) {
            // Não faz nada, mantém o valor vazio
        } else if (newCpf.length <= 3) {
            newCpf = newCpf.replace(/^(\d{0,3})/, "$1")
        } else if (newCpf.length <= 6) {
            newCpf = newCpf.replace(/^(\d{3})(\d{0,3})/, "$1.$2")
        } else if (newCpf.length <= 9) {
            newCpf = newCpf.replace(/^(\d{3})(\d{3})(\d{0,3})/, "$1.$2.$3")
        } else {
            newCpf = newCpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{0,2})/, "$1.$2.$3-$4")
        }

        const cpfValid = isCpfValid(newCpf.replace(/\D/g, "")) // Validação completa do CPF

        return { cpf: newCpf, isValid: cpfValid }
    }
}

// Função para validar o CPF com os dígitos verificadores
const isCpfValid = (cpf: string): boolean => {
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida como '11111111111'
    }

    let sum = 0
    let remainder

    // Validação do primeiro dígito verificador
    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
    }
    remainder = (sum * 10) % 11

    if (remainder === 10 || remainder === 11) remainder = 0
    if (remainder !== parseInt(cpf.substring(9, 10))) return false

    sum = 0

    // Validação do segundo dígito verificador
    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
    }
    remainder = (sum * 10) % 11

    if (remainder === 10 || remainder === 11) remainder = 0
    if (remainder !== parseInt(cpf.substring(10, 11))) return false

    return true
}


export const useHandleFullNameChange = () => {
    return useCallback((event: React.ChangeEvent<HTMLInputElement>, setState: React.Dispatch<React.SetStateAction<FormStateProps>>) => {
        const fullName = event.target.value
        setState((prevState: FormStateProps) => ({
            ...prevState,
            fullName,
            isFullNameValid: validate(fullName, 'name')
        }))
    }, [])
}

export const useHandleEmailChange = () => {
    return useCallback((event: React.ChangeEvent<HTMLInputElement>, setState: React.Dispatch<React.SetStateAction<FormStateProps>>) => {
        const email = event.target.value
        setState((prevState: FormStateProps) => ({
            ...prevState,
            email,
            isEmailValid: validate(email, 'email')
        }))
    }, [])
}

export const useHandleCpfChange = () => {
    return useCallback((event: React.ChangeEvent<HTMLInputElement>, setState: React.Dispatch<React.SetStateAction<FormStateProps>>) => {
        const cpf = event.target.value
        setState((prevState: FormStateProps) => ({
            ...prevState,
            cpf: validate(cpf, 'cpf').cpf,
            isCpfValid: validate(cpf, 'cpf').isValid
        }))
    }, [])
}

export const useHandleCellPhoneChange = () => {
    return useCallback((event: React.ChangeEvent<HTMLInputElement>, setState: React.Dispatch<React.SetStateAction<FormStateProps>>) => {
        const phone = event.target.value
        setState((prevState: FormStateProps) => ({
            ...prevState,
            phone: validate(phone, 'phone').phone,
            isPhoneValid: validate(phone, 'phone').isValid
        }))
    }, [])
}
export const useHandleVehicleChange = () => {
    return useCallback((value: any, setState: React.Dispatch<React.SetStateAction<FormStateProps>>) => {
        const selectedVehicle = value
        setState((prevState: FormStateProps) => ({
            ...prevState,
            selectedVehicle,
            isSelectedVehicleValid: validate(selectedVehicle, 'select'),
        }))
    }, [])
}

export const useHandleSelectedTeamChange = () => {
    return useCallback((value: any, setState: React.Dispatch<React.SetStateAction<FormStateProps>>) => {
        const selectedTeam = value
        const teamOption: Option = options.find(opt => opt.team === value) || { team: '', unit: [], id_unit: [] }

        // Atualizamos o unitOptions para extrair os labels das unidades corretamente
        setState((prevState: FormStateProps) => ({
            ...prevState,
            selectedTeam,
            isSelectedTeamValid: validate(selectedTeam, 'select'),
            unitOptions: { 
                labels: teamOption.unit.map(unit => unit.label), // Extrai apenas o label das unidades
                values: teamOption.id_unit 
            }
        }))
    }, [])
}

export const useHandleSelectedUnitChange = () => {
    return useCallback((value: any, setState: React.Dispatch<React.SetStateAction<FormStateProps>>) => {
        const selectedUnit = value
        setState((prevState: FormStateProps) => ({
            ...prevState,
            selectedUnit,
            isSelectedUnitValid: validate(selectedUnit, 'select')
        }))
    }, [])
}
