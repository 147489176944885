import React, { useState, useEffect, useRef } from 'react'
import names from '@/app/utils/names'
import { IChatMessage } from '@/app/interface/IChatMessage'
import { IChatbotProps } from '@/app/interface/IChatbotProps'
import styles from '@/styles/Chatbot.module.scss'
import validator from 'validator'
import handleSubmitChatbot from '@/app/services/handleSubmitChatbot'
import { useRouter } from "next/router"
import { carOptions } from '@/app/utils/formData'
import ChatHeader from './ChatHeader'
import ChatMessages from './ChatMessages'
import ChatInput from './ChatInput'
import { optionsChatbot as options } from '@/app/utils/formData'

// Função para remover acentos de uma string
const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

function formatCPF(cpf: string) {
  cpf = cpf.replace(/\D/g, "") // Remove tudo exceto números

  if (cpf.length > 11) { // Se mais de 11 números, truncar.
      cpf = cpf.substring(0, 11)
  }

  // Formatação
  if (cpf.length > 3 && cpf.length <= 6) {
      cpf = cpf.replace(/^(\d{3})(\d{0,3})/, "$1.$2")
  } else if (cpf.length > 6 && cpf.length <= 9) {
      cpf = cpf.replace(/^(\d{3})(\d{3})(\d{0,3})/, "$1.$2.$3")
  } else if (cpf.length > 9) {
      cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{0,2})/, "$1.$2.$3-$4")
  }

  return cpf
}

function isValidCPF(cpf: string) {
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false
  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(10, 11))) return false
  return true
}

const extractFullName = (input: string) => {
  const people = []

  const sanitizedInput = removeAccents(input).replace(/[.,\/#!$%\^&\*:{}=\-_`~()]/g, "").toLowerCase()
  const wordsSanitized = sanitizedInput.split(' ')
  const words = input.replace(/[.,\/#!$%\^&\*:{}=\-_`~()]/g, "").toLowerCase().split(' ')

  // Verificar cada palavra na lista de nomes
  for (let i = 0; i < wordsSanitized.length; i++) {
    const word = wordsSanitized[i]
    if (names.includes(word.charAt(0).toUpperCase() + word.slice(1))) {
      people.push(words[i].charAt(0).toUpperCase() + words[i].slice(1))
    } else if (people.length > 0) {
      // Se um nome já foi encontrado e o próximo nome não está na lista, pare a verificação
      break
    }
  }

  // Retornar o nome completo detectado ou uma string vazia se nenhum nome for encontrado
  return people.length > 0 ? people.join(' ') : ''
}

const Chatbot: React.FC<IChatbotProps> = ({ isOpen, setChatbotOpen }) => {
  const [step, setStep] = useState<number>(1)
  const [fullName, setFullName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [cpf, setCpf] = useState<string>('')
  const [cpfEdit, setCpfEdit] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [phoneEdit, setPhoneEdit] = useState<string>('')
  const [team, setTeam] = useState<string>('')
  const [teamEdit, setTeamEdit] = useState<string>('')
  const [unit, setUnit] = useState<string>('')
  const [unitEdit, setUnitEdit] = useState<string>('')
  const [model, setModel] = useState<string>('')
  const [modelEdit, setModelEdit] = useState<string>('')
  const [message, setMessage] = useState<string>('') 
  const [field, setField] = useState<string>('') 
  const [chosenOptions, setChosenOptions] = useState<any[]>([])
  const [confirmation, setConfirmation] = useState<{ [key: string]: boolean }>({
    Nome: false,
    "E-mail": false,
    CPF: false,
    Telefone: false,
    Equipe: false,
    Unidade: false,
    Modelo: false,
    Assunto: false
  })
  const [conversation, setConversation] = useState<IChatMessage[]>([])
  const finalMessageRef = useRef<HTMLDivElement>(null)
  const chatbotRef = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const textInputRef = useRef<HTMLInputElement>(null)
  const [isCpfValid, setIsCpfValid] = useState<boolean>(true)
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true)
  const [unitOptions, setUnitOptions] = useState<{ unit: string[], id_unit: number[] }>({ unit: [], id_unit: [] })
  const [awaitingNameConfirmation, setAwaitingNameConfirmation] = useState<boolean>(false)
  const router = useRouter()
  const { asPath } = router
  const landingPage = 'byd'
  const params = asPath.split('?')[1] ?? ''

  useEffect(() => {
    if (isOpen) {
      addToConversation('bot', 'Oi, que bom ver você por aqui! 😄 Antes de começarmos, poderia me informar seu <b>NOME COMPLETO</b>? Assim, consigo te oferecer um atendimento mais personalizado.')
    }
  }, [isOpen])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (finalMessageRef.current) {
        finalMessageRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }, [conversation])

  const handleClickOutside = (event: MouseEvent) => {
    if (chatbotRef.current && !chatbotRef.current.contains(event.target as Node)) {
      handleClose()
    }
  }

  const handleClose = () => {
    setChatbotOpen(false)
    setConversation([])
    setFullName('')
    setEmail('')
    setCpf('')
    setPhone('')
    setTeam('')
    setTeamEdit('')
    setUnit('')
    setUnitEdit('')
    setModel('')
    setMessage('')
    setCpfEdit('')
    setPhoneEdit('')
    setChosenOptions([])
    setStep(1)
    setUnitOptions({ unit: [], id_unit: [] })
    setConfirmation({
      Nome: false,
      "E-mail": false,
      CPF: false,
      Telefone: false,
      Equipe: false,
      Unidade: false,
      Modelo: false,
      Assunto: false
    })
    setAwaitingNameConfirmation(false)
  }

  const addToConversation = (sender: 'bot' | 'user', text: string, firstMsgBot: boolean = true, isCode: boolean = false, isEdit: boolean = false, confirmBtn: boolean = false) => {
    setConversation(conv => [...conv, { sender, text, firstMsgBot, isCode, isEdit, confirmBtn }])
  }

  const userReply = (input: string) => {
    addToConversation('user', input)
    let nextStep = step
    let currentEditingField = field

    switch (step) {
      case 1:
        nextStep = validateDataName(input, nextStep)
        break
      case 2:
        nextStep = validateDataEmail(input, nextStep)
        break
      case 3:
        nextStep = validateDataCpf(input, nextStep)    
        break
      case 4:
        nextStep = validateDataPhone(input, nextStep)    
        break
      case 5:
        nextStep = validateDataTeam(input, nextStep)  
        break
      case 6:
        nextStep = validateDataUnit(input, nextStep)
        break
      case 7:
        nextStep = validateDataModel(input, nextStep)
        break
      case 8:
        nextStep = validateData(input, nextStep)
        break
      case 9:
        nextStep = editData(input, nextStep)
        if (nextStep === 10) {
          const body = {
            name: fullName,
            email,
            cpf,
            cellPhone: phone,
            unit,
            model,
            message
          }
          handleSubmitChatbot(body, landingPage, model, params, team)
        }
        break
      case 11:
        const fieldsToConfirm = input.split(', ')
        fieldsToConfirm.forEach(field => {
          if (Object.keys(confirmation).includes(field)) {
            confirmation[field] = true
          }
        })
        setConfirmation({ ...confirmation })
        currentEditingField = Object.keys(confirmation).find(key => confirmation[key] === true) ?? ''
        if (currentEditingField) {
          addToConversation('bot', `Por favor, forneça o novo valor para ${currentEditingField}.`)
        }
        nextStep = 12
        break
      case 12:
        if (input.trim() !== '') {
          switch (currentEditingField) {
            case 'Nome':
              setFullName(input)
              break
            case 'E-mail':
              setEmail(input)
              break
            case 'CPF':
              setCpf(input)
              break
            case 'Telefone':
              setPhone(input)
              break
            case 'Equipe':
              setTeam(input)
              break
            case 'Unidade':
              setUnit(input)
              break
            case 'Modelo':
              setModel(input)
              break
            case 'Assunto':
              setMessage(input)
              break
          }
          confirmation[currentEditingField] = false
          addToConversation('bot', `${currentEditingField} atualizado para ${input}.`)
        }
        const lastEdititingField = currentEditingField
        currentEditingField = Object.keys(confirmation).find(key => confirmation[key]) ?? ''
        if (currentEditingField) {
          addToConversation('bot', `Forneça o novo valor para ${currentEditingField}.`, false)
        } else {
          nextStep = confirmData(lastEdititingField, input)
        }
        break
    }
    setStep(nextStep)
    setField(currentEditingField)
  }

  const validateDataName = (input: string, nextStep: number) => {
    if (!input || !input.trim()) {
      addToConversation('bot', `Não entendi muito bem sua resposta, repita pra mim por favor.`)
      return nextStep
    }
    const name = extractFullName(input)

    if (awaitingNameConfirmation) {
      if (input.toLowerCase() === 'sim') {
        setAwaitingNameConfirmation(false)
        addToConversation('bot', `Muito prazer, ${fullName.split(' ')[0]}! Agora que já sei como te chamar, me informe o seu <b>E-MAIL</b>, por favor?`)
        return nextStep + 1
      } else if (input.toLowerCase() === 'não') {
        addToConversation('bot', `Nesse caso, por favor, informe seu <b>NOME COMPLETO</b> abaixo.`)
        setAwaitingNameConfirmation(false)
        return nextStep
      } else {
        addToConversation('bot', `Desculpe, só pra confirmar, seu nome é "${input}"?`)
        addToConversation('bot', '', false, false, false, true)
        return nextStep
      }
    }
    
    if (!name && !awaitingNameConfirmation) {
      addToConversation('bot', `Desculpe, só pra confirmar, seu nome é "${input}"?`)
      addToConversation('bot', '', false, false, false, true)
      setAwaitingNameConfirmation(true)
      const words = input.replace(/[.,\/#!$%\^&\*:{}=\-_`~()]/g, "").toLowerCase().split(' ')
      const wordsSanitized: string[] = []
      words.forEach(word => {
        wordsSanitized.push(word.charAt(0).toUpperCase() + word.slice(1))
      })
      setFullName(wordsSanitized.join(' '))
      return nextStep
    }
    setFullName(name)
    addToConversation('bot', `Muito prazer, ${name.split(' ')[0]}! Agora que já sei como te chamar, me informe o seu <b>E-MAIL</b>, por favor?`)
    return nextStep + 1
  }

  const validateDataEmail = (input: string, nextStep: number) => {
    const isValidEmail = validator.isEmail(input)
    if (!input || !input.trim() || !isValidEmail) {
      addToConversation('bot', `Este não é um e-mail válido, repita pra mim por favor.`)
      return nextStep
    }
    setEmail(input)
    addToConversation('bot', 'Entendi! Qual seria o seu <b>CPF</b>?')
    return nextStep + 1
  }

  const validateDataCpf = (input: string, nextStep: number) => {
    if (!input || !input.trim() || !isCpfValid) {
      addToConversation('bot', `Este não é um CPF válido, repita pra mim por favor.`)
      return nextStep
    }
    addToConversation('bot', `Certo! Qual seria o seu <b>NÚMERO DE TELEFONE</b> para que possamos entrar em contato com você?`)
    return nextStep + 1
  }

  const validateDataPhone = (input: string, nextStep: number) => {
    if (!input || !input.trim() || !isPhoneValid) {
      addToConversation('bot', `Este não é um numero de telefone válido, repita pra mim por favor.`)
      return nextStep
    }
    addToConversation('bot', `Perfeito! E por qual equipe você deseja ser atendido? Isso ajuda a gente a entender melhor as suas necessidades.`)
    return nextStep + 1
  }

  const validateDataTeam = (input: string, nextStep: number) => {
    const isValidTeam = options.some(data => data.team === input)
    if (!input || !input.trim() || !isValidTeam) {
      addToConversation('bot', `Esta não é uma equipe válida, repita pra mim por favor.`)
      return nextStep
    }
    setTeam(input)
    addToConversation('bot', `Certo! E qual é a unidade que você deseja ser atendido?`)
    return nextStep + 1
  }

  const validateDataUnit = (input: string, nextStep: number) => {
    const isValidUnit = unitOptions.unit.includes(input)
    if (!input || !input.trim() || !isValidUnit) {
      addToConversation('bot', `Esta não é uma unidade válida, repita pra mim por favor.`)
      return nextStep
    }
    setUnit(input)
    addToConversation('bot', `Ótimo! Qual modelo de carro você deseja?`)
    return nextStep + 1
  }

  const validateDataModel = (input: string, nextStep: number) => {
    const isValidModel = carOptions.some(option => option.value === input)
    if (!input || !input.trim() || !isValidModel) {
      addToConversation('bot', `Este não é um modelo válido, repita pra mim por favor.`)
      return nextStep
    }
    setModel(input)
    addToConversation('bot', `Ótimo, ${fullName.split(' ')[0]}. Agora me conta, qual assunto você gostaria de discutir conosco hoje?`)
    return nextStep + 1
  }

  const validateData = (input: string, nextStep: number) => {
    if (!input || !input.trim()) {
      addToConversation('bot', `Não entendi muito bem sua resposta, repita pra mim por favor.`)
      return nextStep
    }
    setMessage(input)
    addToConversation('bot', `Certo, ${fullName.split(' ')[0]}, só um momento enquanto verificamos os dados coletados.`)
    nextStep = confirmData('Assunto', input)
    return nextStep
  }

  const editData = (input: string, nextStep: number) => {
    if (input.toLowerCase() === 'sim') {
      addToConversation('bot', `Prontinho, ${fullName.split(' ')[0]}! 👍 Entraremos em contato com você em breve. Se preferir uma resposta imediata, sinta-se à vontade para falar com um de nossos atendentes agora mesmo. Basta clicar no botão abaixo e te direcionaremos para o WhatsApp!`)
      addToConversation('bot', '', false, true)
      return nextStep + 1
    } else if (input.toLowerCase() === 'não') {
      addToConversation('bot', `Entendi, quais informações precisamos corrigir?`)
      addToConversation('bot', '', false, false, true)
      return nextStep + 2
    } else {
      addToConversation('bot', `Não entendi muito bem sua resposta, repita pra mim por favor.`)
      return nextStep
    }
  }

  const confirmData = (lastEditingField: string = '', input: string = '') => {
    const fieldMappings: any = {
      Nome: fullName,
      'E-mail': email,
      CPF: cpf,
      Telefone: phone,
      Equipe: team,
      Unidade: unit,
      Modelo: model,
      Assunto: message,
    }
    let confirmationMessage = '<b>Seus dados estão corretos?</b><br>'
    for (const field in fieldMappings) {
      const value = lastEditingField === field ? input : fieldMappings[field]
      confirmationMessage += `${field}: ${value}<br>`
      if (lastEditingField === field) {
        fieldMappings[field] = input
      }
    }
    addToConversation('bot', confirmationMessage.trim(), false)
    addToConversation('bot', '', false, false, false, true)
    setChosenOptions([])
    setTeamEdit('')
    setUnitEdit('')
    setCpfEdit('')
    setPhoneEdit('')
    return 9
  }

  const handleCheckboxChange = (e: any) => {
    const value = e.target.value
    if (e.target.checked) {
      setChosenOptions([...chosenOptions, value])
    } else {
      setChosenOptions(chosenOptions.filter(item => item !== value))
    }
  }

  const handleTeamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUnitOptions({ unit: [], id_unit: [] })
    let teamOptions
    setTeam(e.target.value)
    if (field === 'Equipe') setTeamEdit(e.target.value)
    validateSelectedTeam(e.target.value)

    teamOptions = options.find(opt => opt.team === e.target.value)

    if (teamOptions) {
      setUnitOptions({ unit: teamOptions.unit, id_unit: teamOptions.id_unit })
    }
  }

  const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUnit(e.target.value)
    if (field === 'Unidade') setUnitEdit(e.target.value)
    validateSelectedUnit(e.target.value)
  }

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setModel(e.target.value)
    if (field === 'Modelo') setModelEdit(e.target.value)
    validateSelectedModel(e.target.value)
  }

  const validateSelectedTeam = (value: string) => {
    if (value.length > 0) {
      userReply(value)
      return true
    }
    return false
  }

  const validateSelectedUnit = (value: string) => {
    if (value.length > 0) {
      userReply(value)
      return true
    }
    return false
  }

  const validateSelectedModel = (value: string) => {
    if (value.length > 0) {
      userReply(value)
      return true
    }
    return false
  }

  const handleCellPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "")
    if (value.length > 11) {
      value = value.substring(0, 11)
    }
    if (value.length === 0) {
    } else if (value.length <= 2) {
      value = value.replace(/^(\d{0,2})/, "($1")
    } else if (value.length <= 7) {
      value = value.replace(/^(\d{0,2})(\d{0,5})/, "($1) $2")
    } else {
      value = value.replace(/^(\d{0,2})(\d{0,5})(\d{0,4})/, "($1) $2-$3")
    }
    setPhone(value)
    if (field === 'Telefone') setPhoneEdit(value)
    setIsPhoneValid(validatePhone(value))
  }

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "") // Remove tudo exceto números
        
    if (value.length > 11) { // Se mais de 11 números, truncar.
        value = value.substring(0, 11)
    }

    // Formatação
    value = formatCPF(value)

    setCpf(value)
    if (field === 'CPF') setCpfEdit(value)
    setIsCpfValid(isValidCPF(value.replace(/\D/g, '')))
  }

  const validatePhone = (value: string) => {
    if (value.length === 15 || !value) {
      return true
    }
    return false
  }

  const adjustHeight = () => {
    const field = textAreaRef.current
    if (field) {
      field.rows = 1 // Inicializar com 1 linha
      const computed = window.getComputedStyle(field)
      const fontSize = parseInt(computed.getPropertyValue('font-size'), 10)
      let lineHeight = parseInt(computed.getPropertyValue('line-height'), 10)
      
      if (isNaN(lineHeight)) {
        lineHeight = fontSize * 1.2 // Caso o lineHeight não seja definido
      }
  
      // Calcular a altura mínima necessária para uma linha
      const minHeight = lineHeight
  
      // Se o scrollHeight for maior que a altura mínima, ajustar o número de linhas
      const textLines = Math.floor((field.scrollHeight - field.clientHeight + minHeight) / lineHeight)
      field.rows = Math.min(Math.max(textLines, 1), 4) // Garantir que não diminua abaixo de 1 e limite a 4
    }
  }
  

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey && e.currentTarget.value.trim()) {
      userReply(e.currentTarget.value.trim())
      e.currentTarget.value = ''
      e.preventDefault()
      adjustHeight()
    }
  }

  const handleSendClick = () => {
    const input = textAreaRef.current || textInputRef.current
    if (input && input.value.trim()) {
      userReply(input.value.trim())
      input.value = ''
      adjustHeight()
    }
  }

  const handleWhatsAppClick = () => {
    const data = `Olá meu nome é ${fullName}. Quero falar sobre: ${message}`
    switch (team) {
      case 'GO':
        window.open(`https://wa.me/6296534029?text=${encodeURIComponent(data)}`, '_blank')
        break
      case 'MG':
        window.open(`https://wa.me/31998495688?text=${encodeURIComponent(data)}`, '_blank')
        break
      case 'BSB':
        window.open(`https://wa.me/61999636740?text=${encodeURIComponent(data)}`, '_blank')
        break
      case 'ES':
        window.open(`https://wa.me/27997169560?text=${encodeURIComponent(data)}`, '_blank')
        break
    }
  }

  return (
    <div ref={chatbotRef} className={`${styles.chatbot} ${isOpen ? styles.open : ''}`}>
      <div className={styles.chatbotContent}>
        <ChatHeader handleClose={handleClose} />
        <div className={styles.mainChatbot}>
          <ChatMessages 
            conversation={conversation} 
            finalMessageRef={finalMessageRef}
            handleWhatsAppClick={handleWhatsAppClick}
            handleCheckboxChange={handleCheckboxChange}
            userReply={userReply}
            step={step}
            options={options}
            team={team}
            carOptions={carOptions}
            chosenOptions={chosenOptions}
            teamEdit={teamEdit}
            unit={unit}
            unitEdit={unitEdit}
            model={model}
            modelEdit={modelEdit}
            field={field}
            handleTeamChange={handleTeamChange}
            handleUnitChange={handleUnitChange}
            handleModelChange={handleModelChange}
            unitOptions={unitOptions}
          />
          <ChatInput 
            step={step}
            field={field}
            phone={phone}
            phoneEdit={phoneEdit}
            cpf={cpf}
            cpfEdit={cpfEdit}
            handleKeyDown={handleKeyDown}
            handleSendClick={handleSendClick}
            handleCpfChange={handleCpfChange}
            handleCellPhoneChange={handleCellPhoneChange}
            adjustHeight={adjustHeight}
            textAreaRef={textAreaRef}
            textInputRef={textInputRef}
          />
        </div>
      </div>
    </div>
  )
}

export default Chatbot
