import React from "react"
import CustomLink from "../../CustomLink"
import { ExploreContainerProps } from "@/app/interface/components"
import ImageComponentHeightFixed from "../../ImageComponentHeightFixed"

const ExploreContainer: React.FC<ExploreContainerProps> = ({ styles, isExploreOpen, setIsExploreOpen, setIsMenuOpen }) => {
    return (
        <ul className={`${styles.explore}${isExploreOpen ? ' ' + styles.show : ''}`}>
            <li>
                <CustomLink href="/sobre" onClick={() => {
                    setIsExploreOpen(false)
                    setIsMenuOpen?.(false)
                }}>
                    <span>Sobre a BYD</span>
                    <div>
                        <ImageComponentHeightFixed src={`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/about.webp`} height={132} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink href="/consorcio" onClick={() => {
                    setIsExploreOpen(false)
                    setIsMenuOpen?.(false)
                }}>
                    <span>Consórcio BYD</span>
                    <div>
                        <ImageComponentHeightFixed src={`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/consorcio.webp`} height={132} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            {<li>
                <CustomLink href="/pos-venda" onClick={() => {
                    setIsExploreOpen(false)
                    setIsMenuOpen?.(false)
                }}>
                    <span>Pós-Venda BYD</span>
                    <div>
                        <ImageComponentHeightFixed src={`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/pos-venda.webp`} height={132} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>}
            {/*<li>
                <CustomLink href="/blog" onClick={() => {
                setIsExploreOpen(false)
                setIsMenuOpen?.(false)
                }}>
                    <span>BLOG</span>
                        <div>
                            <ImageComponentHeightFixed src=`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/blog.webp" height={132} loading="lazy" alt="" />
                        </div>
                </CustomLink>
            </li>*/}
            <li>
                <CustomLink target="_blank" href="https://www.seminovosvitoriamotorsbyd.com.br" onClick={() => {
                    setIsExploreOpen(false)
                    setIsMenuOpen?.(false)
                }}>
                    <span>Seminovos</span>
                    <div>
                        <ImageComponentHeightFixed src={`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/seminovos.webp`} height={132} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
        </ul>
    )
}

export default React.memo(ExploreContainer)